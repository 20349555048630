
















import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { ApexCharsSeries } from "@/shared/dtos/DashboardsDtos/ApexCharsSeries";
@Component({
  components: { VueApexCharts },
})
export default class ChartLine extends Vue {
  @Prop({ default: [] }) series!: ApexCharsSeries[];
  @Prop({ default: [] }) categories!: string[];
  @Prop({ default: "" }) title!: string;
  public created() {}

  public get chartOptions() {
    return {
      chart: {
        toolbar: { show: false },
        dropShadow: {
          enabled: true,
          top: 5,
          left: 0,
          blur: 4,
          opacity: 0.1,
        },
      },
      stroke: {
        curve: "smooth",
        dashArray: [0, 8],
        width: [4, 2],
      },
      grid: {
        borderColor: "#e7e7e7",
      },
      legend: {
        show: true,
      },
      colors: ["#F97794", "#b8c2cc"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          inverseColors: false,
          gradientToColors: ["#7367F0", "#b8c2cc"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: 0,
        hover: {
          size: 5,
        },
      },
      xaxis: {
        labels: {
          style: {
            cssClass: "text-grey fill-current",
          },
        },
        axisTicks: {
          show: false,
        },
        categories: this.categories,
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            cssClass: "text-grey fill-current",
          },
        },
      },
      tooltip: {
        x: { show: false },
      },
    };
  }
}
